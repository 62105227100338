<template>
    <section class="technology-1 py-5 overflow-hidden">
        <div class="container">
            <h1 class="display-4 font-weight-bolder text-center mb-5 font-noto-serif-tc">
                晶體冷凍技術 無痛脫毛技術
            </h1>
            <div class="row mb-4">
                <div class="col-md-7 d-flex flex-column justify-content-center align-items-center"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <h3 class="mb-0">
                        治療頭尖端位保持5°C恆溫
                    </h3>
                    <h3>
                        將表皮膚溫度降低25°C
                    </h3>
                </div>
                <div class="col-md-5">
                    <img class="w-100 image-tech"
                         data-aos="fade-left"
                         data-aos-duration="1000"
                         alt="高速脫毛技術"
                         src="../../../assets/campaign/elysion-pro-comb/technology-1.svg">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "ElysionProCombTechnology1",
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .technology-1 {
        background-color: $background-color-alt;
        color: $text-color;

        .image-tech {
            border: solid 10px #e57a30;
            border-radius: 15px;
        }
    }
</style>
